<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs10
      class="pb-12"
    >
      <v-img
        :src="require('../assets/perfil.png')"
        aspect-ratio="1"
        class="mx-auto my-2"
        contain
        width="150"
      ></v-img>

      <h1 class="primary--text text--darken-4 text-center">{{ nombre }}</h1>

      <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
        <v-form @submit.prevent="passes(send)">
          <v-row>
            <v-col cols="12" md="4">
              <ValidationProvider name="Usuario" rules="required|min:2|max:50" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="usuario"
                  type="text"
                  label="Nombre de usuario"
                  value="usuario"
                  required
                  :error-messages="errors"
                  :success="valid"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider name="Celular" rules="required|min:2|max:50" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="celular"
                  type="tel"
                  label="Celular"
                  required
                  :error-messages="errors"
                  :success="valid"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider name="Email" rules="email" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="email"
                  type="email"
                  label="Email"
                  :error-messages="errors"
                  :success="valid"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <ValidationProvider name="ClaveNueva" rules="min:2|max:50|confirmed:confirmation" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="claveNueva"
                  type="password"
                  label="Nueva Contraseña"
                  :error-messages="errors"
                  :success="valid"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider name="ClaveNueva2" vid="confirmation" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="claveNueva2"
                  type="password"
                  label="Confirmar Nueva Contraseña"
                  :error-messages="errors"
                  :success="valid"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider name="Clave" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="clave"
                  type="password"
                  label="Contraseña"
                  required
                  :error-messages="errors"
                  :success="valid"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <div align-self-center class="mt-2 text-center">
            <v-btn type="submit" x-large @click.prevent="passes(edit)" color="primary darken-3"
              dark :loading="saving" :disabled="saving">
              Guardar
            </v-btn>
          </div>
        </v-form>
      </ValidationObserver>
    </v-flex>
  </v-layout>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    saving: false,
    nombre: '',
    usuario: '',
    celular: '',
    email: '',
    clave: '',
    claveNueva: '',
    claveNueva2: '',
  }),

  mounted () {
    this.loadData()
  },

  methods:{
    loadData: function () {
      this.loading = true

      this.$soapClient.call('obtenerPerfil')
      .then((response) => {
        let resultado = response.data.resultado

        this.nombre = resultado.Nombre
        this.usuario = resultado.Codigo.toString()
        this.celular = resultado.Celular
        this.email = resultado.Email
        this.clave = ''
        this.claveNueva = ''
        this.claveNueva2 = ''
      })
      .catch(() => {
        this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo procesar su solicitud', 'error')
      })
      .then(() => {
        this.loading = false
      })
    },

    edit: function () {
      this.saving = true

      this.$soapClient.call('editPerfil', {
        Codigo: this.usuario,
        Celular: this.celular,
        Email: this.email,
        claveActual: this.clave,
        claveNueva: this.claveNueva2
      })
      .then(() => {
        this.clave = ''
        this.claveNueva = ''
        this.claveNueva2 = ''

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron actualizados!', 'success')
      })
      .catch((error) => {
        if ('message' in error) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    }
  }
};
</script>
